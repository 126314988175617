// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/** Variable de entorno que apuntan al servidor de pruebas */
export const environment = {
  // production: false,
  // /** URL para correr proyecto localmente 2 */
  // url: '/api',
  // // urlcrm: 'https://simisoftware.com/crm/',
  // urlcrm: 'https://simisoftware.com/crm_anderson/',
  // urlifr: 'https://simi-cloud.com.co',
  // ws_url_users: 'https://simi-cloud.com.co:3001/',
  // ws_url_simipedia: 'https://simi-cloud.com.co:3002/',
  // SERVER_LARAVEL_SOCKET_IO: 'https://simi-cloud.com.co:3004/',
  // SERVER_LARAVEL: 'https://simisoftware.com',
  // // endpointCrm: 'crm',
  // endpointCrm: 'crm_anderson',
  // // eso es usado por la v2 del request no se pone el / al final por que el request lo agrega
  // /* SERVER_LARAVEL: 'http://localhost:8000' */

  production: true,
    url: 'https://softwaresimi.com',
    urlcrm: 'https://api.siminmobiliarias.com/crm_pre/',
    urlifr: 'https://softwaresimi.com',
    ws_url_users: 'https://simi-cloud.com.co:3001/',
    ws_url_simipedia: 'https://simi-cloud.com.co:3002/',
    endpointCrm: 'crm_pre',
    SERVER_LARAVEL_SOCKET_IO: 'https://simi-cloud.com.co:3003/',
    // eso es usado por la v2 del request no se pone el / al final por que el request lo agrega
    SERVER_LARAVEL: 'https://api.siminmobiliarias.com/crm_pre'

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
