import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const currentUser = this.authenticationService.currentUserValue;
    if (!currentUser) {
      this.router.navigate(['/login']);
      return false;
    } else {
      return this.authenticationService.isLogued().pipe(map((data: any) => {
        if (data.status === 0) {
          this.authenticationService.logout();
          this.router.navigate(['/login']);
          return false;
        } else {
          return true;
        }
      }));
    }
    // if (currentUser) {
    //   // authorised so return true
    //   return true;
    // }

    // // not logged in so redirect to login page with the return url
    // // this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    // this.router.navigate(["/login"]);
    // return false;
  }
}
