import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from '../_services/loader.service';
import { AuthenticationService } from '../_services/authentication.service';
import { Router } from '@angular/router';
import { GlobalVariables } from './global_variables';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    private globalVariables: GlobalVariables
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authRequest = req;
    if (this.authenticationService.currentUserValue) {
      if (req.url.includes(this.authenticationService.currentUserValue.urlApi) || req.url.includes
      (this.authenticationService.currentUserValue.urlApiErp)) {
        authRequest = req.clone({headers: req.headers.set('token', `${localStorage.getItem('tokenUserApi')}`)});
      }
    }
    if (
      !req.url.includes('models/login.php?data=12') &&
      !req.url.includes('models/herramientas.php?data=32') &&
      !req.url.includes('cdn.datatables.net')
    ) {
      // console.log("Se va agregar uno mas al contador con total: " + this.globalVariables.countRequests,req.url);
      this.globalVariables.countRequests = this.globalVariables.countRequests + 1;
      this.showLoader();

      return next.handle(authRequest).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.onEnd();
            }
          },
          (err: any) => {
            this.onEnd();
          }
        )
      );
    } else {
      return next.handle(authRequest);
    }
  }

  public onEnd(): void {
    if (this.globalVariables.countRequests === 1) {
      this.globalVariables.countRequests = this.globalVariables.countRequests - 1;
      this.hideLoader();
    } else if (this.globalVariables.countRequests < 0) {
      this.globalVariables.countRequests = 0;
      this.hideLoader();
    } else {
      this.globalVariables.countRequests = this.globalVariables.countRequests - 1;
    }
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    // setTimeout(() => {
      this.loaderService.hide();
    // }, 10000);
  }
}
