import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'table2columns'
})
export class Table2columnsPipe implements PipeTransform {
  columns: any;
  transform(value: any) {

    this.columns = value.filter((v, i) => i % 2 === 0).map((v, i) => [value[i * 2], value[i * 2 + 1]]);
    return this.columns;

  }

}
