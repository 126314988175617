import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable, Subscriber } from 'rxjs';
import { retry,map, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LaravelSocketService {

  private socket;

  constructor() {
      const options = {
        transports: ['websocket'],
        secure: true
      };
      this.socket = io(environment.SERVER_LARAVEL_SOCKET_IO, options);
  }


  /**
   * Observador de cuando se envia una notificacion para el usuario indicado
   * @param idUser
   */
  onUserSessionNotification(idUser): Observable<any>{
    var obs = new Observable((observer: Subscriber<any>) => {
      this.socket.on("App\\Notifications\\v1\\SimiNotification",response => {
        observer.next(response);
      });
    }).pipe(
      map(response => {
        return response.notification
      }),
      filter((notification,index) => {

        if((parseInt(notification.idUser) === parseInt(idUser))){
          return true;
        }

        return false;
      })
    );


    return obs;
  }

}
