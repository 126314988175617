import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, args: string): any {
    if (value.indexOf(args) === -1) {
      return false;
    } else {
      return true;
    }
  }

}
