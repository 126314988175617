import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../../../../_services/authentication.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RequestService } from '../../../../_services/request.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'modal-conten',
  templateUrl: './modal-sincro-portales-inmu.component.html',
  styleUrls: ['./modal-sincro-portales-inmu.component.scss']
})
export class ModalSincroPortalesInmuComponent implements OnInit {
  userCurrent;
  idInm;
  estadoInm;
  portales = [];
  constructor(
    public authenticationService: AuthenticationService,
    public bsModalRef: BsModalRef,
    private request: RequestService
  ) {
    this.userCurrent = authenticationService.currentUserValue;
    setTimeout(() => {
      this.request.globalRequest('post', {inmu: this.idInm, angular: 1}, '/models/portales.php?data=30').subscribe((data) => {
        if (data.error === 0) {
          this.portales = data.data;
        } else {
          this.portales = [];
        }
      });
      // console.log(this.idInm);
    }, 10);
  }

  ngOnInit() {
  }
}
