import { Component, OnInit, OnDestroy } from '@angular/core';
import { Sidenav } from '../../../_models/sidenav';
import { AuthenticationService } from '../../../_services/authentication.service';
import { GlobalVariables } from '../../../_helpers/global_variables';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { RequestService } from '../../../_services/request.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('slideMenu', [
      state('true', style({
        overflow: 'hidden',
        height: '*'
      })),
      state('false', style({
        opacity: '0',
        overflow: 'hidden',
        height: '0px'
      })),
      transition('true => false', animate('400ms ease-in-out')),
      transition('false => true', animate('400ms ease-in-out'))
    ]),
  ]
})
export class SidenavComponent implements OnInit, OnDestroy {
  sidenavView = 2;
  itemsSidenavV2: Sidenav[];
  parentMenu = 1;
  openParentMenu = true;

  constructor(private authenticationService: AuthenticationService, public globalVariables: GlobalVariables,
              public requestService: RequestService) {}

  cambiarMenu(idmenu) {
    this.sidenavView = idmenu;
    this.authenticationService.menuView = idmenu;
  }

  openMenu(index) {
    if (this.sidenavView === 1) {
      if (this.globalVariables.mainMenu[index].openMenu === false) {
        for (let i of this.globalVariables.mainMenu) {
          i.openMenu = false;
        }
        this.globalVariables.mainMenu[index].openMenu = true;
      } else {
        this.globalVariables.mainMenu[index].openMenu = false;
      }
    } else {
      if (this.itemsSidenavV2[index].openMenu === false) {
        for (let i of this.itemsSidenavV2) {
          i.openMenu = false;
        }
        this.itemsSidenavV2[index].openMenu = true;
      } else {
        this.itemsSidenavV2[index].openMenu = false;
      }
    }
  }

  ngOnInit() {
    this.loadMenuV2Parent();
  }

  loadMenuV2Parent() {
    if (this.globalVariables.mainMenuV2) {
      this.itemsSidenavV2 = [];
      // Recorro los modulos principales con la variable i
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.globalVariables.mainMenuV2.length; i++) {
        let validItem = this.itemsSidenavV2.find((x) => {
          return x.title === this.globalVariables.mainMenuV2[i].title
        });
        if (validItem === undefined && this.globalVariables.mainMenuV2[i].childrens.length > 0) {
          this.itemsSidenavV2.push(JSON.parse(JSON.stringify(this.globalVariables.mainMenuV2[i])));
        }
        // Recorro los hijos de los modulos principales con la variable j
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < this.globalVariables.mainMenuV2[i].childrens.length; j++) {
          // Valida si el hijo del modulo pertenece al padre seleccionado del otro menu
          if (this.globalVariables.mainMenuV2[i].childrens[j].parentV2 != this.parentMenu) {
            // Si el padre del hijo del modulo principal no es igual al seleccionado, buscamos el index del modulo padre
            let parentItem = this.itemsSidenavV2.find((x) => {
              return x.title === this.globalVariables.mainMenuV2[i].title
            });
            let indexItem = this.itemsSidenavV2.indexOf(parentItem);
            if (indexItem !== -1) {
              // Validamos si el padre encontrado no tiene hijos
              if (this.itemsSidenavV2[indexItem].childrens) {
                let count = this.itemsSidenavV2[indexItem].childrens.length;
                // Si tiene hijos el modulo principal encontrado, los recorremos en un for con variable k
                for (let k = 0; k < count; k++) {
                  if (this.itemsSidenavV2[indexItem].childrens[k]) {
                    // Validamos que el id del hijo de la iteracion (k) sea igual al hijo de la variable j para saber cual se debe eliminar
                    if (this.itemsSidenavV2[indexItem].childrens[k].id === this.globalVariables.mainMenuV2[i].childrens[j].id) {
                      this.itemsSidenavV2[indexItem].childrens.splice(k, 1);
                      if (this.itemsSidenavV2[indexItem].childrens.length <= 0) {
                        // Si al eliminar el hijo, el padre no tiene mas hijos, se elimina del array
                        this.itemsSidenavV2.splice(indexItem, 1);
                      }
                    }
                  }
                }
              } else {
                // Si no tiene hijos, se elimina el modulo principal
                this.itemsSidenavV2.splice(indexItem, 1);
              }
            }
          }
        }
      }
    } else {
      setTimeout(() => {
        this.loadMenuV2Parent();
      }, 100);
    }
  }

  changeMenuParent(id) {
    if (id !== this.parentMenu) {
      this.parentMenu = id;
      this.loadMenuV2Parent();
    }
  }

  bitacora(event, uri) {
    const data = {
      programa: uri.id,
      id: 1,
      ruta: uri.url
    };
    this.requestService.globalRequestApi('post', data, 'crm/programas/clicks', 'urlApiCrm').subscribe(res => {
      // console.log(res);
    });
  }
  ngOnDestroy() {
  }
}
