import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Functions {
  JSON_to_URLEncoded(element, key?, list?) {
    var list = list || [];
    if (typeof element === 'object') {
      for (var idx in element)
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + '[' + idx + ']' : idx,
          list
        );
    } else {
      list.push(key + '=' + encodeURIComponent(element));
    }
    return list.join('&');
  }
  Object_to_URLEncoded(params) {
    return Object.keys(params)
      .map(key => {
        params[key] = params[key] == null ? '' : params[key];
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      })
      .join('&');
  }
  playAudio(urlSound) {
    let audio = new Audio();
    audio.src = urlSound;
    audio.load();
    audio.play();
  }

  notifyMe(user, message, theIcon, debug, uri, tag, soundID = 0) {
    if (Notification.permission === 'granted') {
      // alert("2");
      // If it's okay let's create a notification
      var sounds = {
        0: 'https://simicrm.app/mwc/js/all-eyes-on-me.mp3',
        1: 'https://simi-cloud.com.co/mwc/js/sound/bbm_tone.mp3',
        2: 'https://simi-cloud.com.co/mwc/js/sound/el_verdadero_terror.mp3'

      }

      var options = {
        body: message,
        icon: theIcon,
        // renotify: true,
        tag: tag,
        sound: sounds[soundID]
      };

      var notification = new Notification(user, options);

      notification.addEventListener(
        'click',
        function(e:any) {
          window.focus();
          e.target.close();
          window.open(uri);
        },
        false
      );

      this.playAudio(sounds[soundID]);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function(permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          var options = {
            body: message,
            icon: theIcon,
            // vrenotify: true,
            tag: tag,
          };

          var notification = new Notification(user, options);

          notification.addEventListener(
            'click',
            function(e:any) {
              window.focus();
              e.target.close();
              window.open(uri);
            },
            false
          );
          this.playAudio();
          // notification.renotify // should return true
        }
      });
    }
  }
}
