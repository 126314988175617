import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class UsuariosSocketService {
  private socket;
  usuariosCon = 0;
  usuariosDisCon = 0;
  inmosCon = 0;
  tblUsersConect = [];
  tblUsersDisConect = [];
  total = 0;

  constructor() {
    const options = {
      transports: ['websocket'],
      secure: true
    };
    this.socket = io(environment.ws_url_users, options);
  }
  nvoUser(user: User) {
    this.socket.emit('nuevoUsuario', {
      id: user.iduser,
      nombreCliente: user.nombre + ' ' + user.apellidos,
      inmo: user.idinmmo,
      idinmo: user.idinmmo
    });
  }
  getNuevoUsuario(): Observable<any> {
    return Observable.create(observer => {
      this.socket.on('nuevousuario', data => {
        observer.next(data);
      });
    });
  }
  getRefreshUsers(): Observable<any> {
    return Observable.create(observer => {
      this.socket.on('refreshUsers', data => {
        observer.next(data);
      });
    });
  }
  emitTblUsersConect() {
    this.socket.emit('tblUsuariosConectados');
  }
  getTblUsersConect(): Observable<any> {
    return Observable.create(observer => {
      this.socket.on('tblusuariosConectados', data => {
        observer.next(data);
      });
    });
  }
  emitTblUsersDisConect() {
    this.socket.emit('tblUsuariosDesConectados');
  }
  getTblUsersDisConect(): Observable<any> {
    return Observable.create(observer => {
      this.socket.on('tblusuariosDesConectados', data => {
        observer.next(data);
      });
    });
  }

  emitNotificationForUser(data)
  {
    this.socket.emit('notificationForUser',data);
  }

  onNotificationUser(): Observable<any>{
    return Observable.create(observer => {
      this.socket.on("notificationForUser",data => {
        observer.next(data);
      });
    });
  }
}
