import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { User } from '../_models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Functions } from '../_helpers/functions';
import { Sidenav } from '../_models/sidenav';
import { RequestService } from './request.service';
import { GlobalVariables } from '../_helpers/global_variables';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public mainMenu: Array<Sidenav>;
  public mainMenuV2: Array<Sidenav>;
  headers: any = '';
  options: any = '';
  public menuView = 2;
  constructor(
    private http: HttpClient,
    private functions: Functions,
    private request: RequestService,
    private globalVariables: GlobalVariables
  ) {
    this.mainMenu = this.globalVariables.mainMenu;
    this.mainMenuV2 = this.globalVariables.mainMenuV2;
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    this.options = { headers: this.headers };
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public setCurrentUserValue(user) {
    this.currentUserSubject.next(user);
  }
  login(username: string = '1023949739', password: string = 'QsN72x', token: string) {
    const loginParams = this.functions.JSON_to_URLEncoded({
      user: username,
      pass: password,
      captcha: token,
      angular: 1
    });
    return this.http.post(
      environment.url + `/models/login.php?data=14`,
      loginParams,
      this.options
    );
  }
  loginLaravel(username: string = '1023949739', password: string = 'QsN72x', url: string) {
    const loginParams = this.functions.JSON_to_URLEncoded({
      user: username,
      pass: password
    });
    return this.http.post(
      url + `/simiwebApi/source/api/v1/login`,
      loginParams,
      this.options
    );
  }
  loginLaravelCrm(username: string = '1023949739', password: string = 'QsN72x', url: string) {
    const loginParams = this.functions.JSON_to_URLEncoded({
      user: username,
      password
    });
    return this.http.post(
      url + `/crm/auth/login`,
      loginParams,
      this.options
    );
  }
  isLogued() {
    return this.http.get(environment.url + `/models/login.php?data=12`);
  }
  logout() {
    // remove user from local storage to log user out
    /* localStorage.removeItem('currentUser');
    localStorage.removeItem('mainMenu');
    localStorage.removeItem('notificaciones');
    localStorage.removeItem('usersActive'); */
    this.closeSessionSimicloud();
    localStorage.clear();
    this.globalVariables.selects = [];
    this.menuView = 2;
    this.currentUserSubject.next(null);
  }

  closeSessionSimicloud() {
    this.request.globalRequest('post', { IdInmmo: this.currentUserValue.idinmmo }, '/models/herramientas.php?data=33');
  }


  loadSelects() {
    this.request
      .globalRequest('post', { IdInmmo: this.currentUserValue.idinmmo }, '/models/herramientas.php?data=32')
      .subscribe((data: any) => {

        // tslint:disable-next-line: forin
        for (const selc in data) {
          localStorage.setItem(selc, JSON.stringify(data[selc]));
        }
        this.globalVariables.selects = data;
      });
  }
  loadMenusUser() {
    this.request
      .globalRequest('post', {}, '/models/login.php?data=13')
      .subscribe((data: any) => {
        this.globalVariables.mainMenu = data.Menu;
        this.globalVariables.mainMenuV2 = data.MenuV2;
        this.globalVariables.mainMenuV2 = data.MenuV2;
        this.globalVariables.mainMenuV2Parent = data.MenuV2Parent;
        const user: User = data.User;
        this.currentUserSubject.next(user);
        localStorage.setItem('currentUser', JSON.stringify(user));
      });
  }
}
