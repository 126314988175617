import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderState } from '../../_models/loader.model';
import { LoaderService } from '../../_services/loader.service';
import { GlobalVariables } from '../../_helpers/global_variables';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  private subscription: Subscription;
  constructor(
    private loaderService: LoaderService,
    public globalVariables: GlobalVariables
  ) { }
  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        setTimeout(() => {
          this.globalVariables.isViewLoader = state.show;
        }, 5);
      });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
