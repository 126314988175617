import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './shell/header/header.component';
import { SidenavComponent } from './shell/sidenav/sidenav.component';
import { ShellComponent } from './shell/shell.component';
import { MainComponent } from './shell/main/main.component';
import { RouterModule } from '@angular/router';
// import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { LoaderComponent } from '../components/loader/loader.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchPipe } from '../_pipes/search.pipe';
import { FormsModule } from '@angular/forms';
// import { ReplacePipe } from '../_pipes/replace.pipe';
import { MomentModule } from 'ngx-moment';
import 'moment/locale/es';

import { PipesModule } from '../_pipes/pipes.module';
import { NotificacionComponent } from './shell/components/notificacion/notificacion.component';
/* pluggin spinner */
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    SearchPipe,
    HeaderComponent,
    SidenavComponent,
    ShellComponent,
    MainComponent,
    LoaderComponent,
    NotificacionComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    PipesModule.forRoot(),
    MomentModule,
    NgxSpinnerModule
  ],
  exports: [
    ShellComponent,
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule
  ],

})
export class CoreModule { }
