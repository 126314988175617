import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SimipediaSocketService {
  private socket;
  constructor(
  ) {
    const options = {
      transports: ['websocket'],
      secure: true
    };
    // console.log('Va crear socket Simipedia');
    this.socket = io(environment.ws_url_simipedia, options);
  }
  onAnalista(): Observable<any> {
    return Observable.create(observer => {
      this.socket.on('analista', data => {
        observer.next(data);
      });
    });
  }
  onCambioTareaAnalista(): Observable<any> {
    return Observable.create(observer => {
      this.socket.on('cambioTareaAnalista', data => {
        observer.next(data);
      });
    });
  }
  onNotification_qualify(): Observable<any> {
    return Observable.create(observer => {
      this.socket.on('notification_qualify', data => {
        observer.next(data);
      });
    });
  }
  onNotifyCredentials(): Observable<any> {
    return Observable.create(observer => {
      this.socket.on('notifyCredentials', data => {
        observer.next(data);
      });
    });
  }
  onNotifyCreateTicketClient(): Observable<any> {
    return Observable.create(observer => {
      this.socket.on('notifyCreateTicketClient', data => {
        observer.next(data);
      });
    });
  }
  onNotifyResponseTicketClient(): Observable<any> {
    return Observable.create(observer => {
      this.socket.on('notifyResponseTicketClient', data => {
        observer.next(data);
      });
    });
  }
  onNotifyResponseUsrResponse(): Observable<any> {
    return Observable.create(observer => {
      this.socket.on('notifyResponseUsrResponse', data => {
        observer.next(data);
      });
    });
  }
  onNotifyCambioTicket(): Observable<any> {
    return Observable.create(observer => {
      this.socket.on('cambioTicketPQRySimi', data => {
        observer.next(data);
      });
    });
  }
  onNotificationUser(): Observable<any>{
    return Observable.create(observer => {
      this.socket.on("notificationUser",data => {        
        observer.next(data);
      });
    });
  }
}
