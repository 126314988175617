import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'momentDateFormat'
})
export class MomentDateFormatPipe implements PipeTransform {

  transform(date: string, format: string = 'YYYY-MM-DD HH:mm:ss'): string {

    let fecha =  moment(date, format).fromNow();

  let vali = '';
    if(fecha == 'Invalid date'){
      vali = 'No tiene fecha';
    }else{
      vali =fecha;
    }
    return vali;
  }

}
