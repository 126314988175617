import { Injectable } from '@angular/core';
import { Sidenav } from '../_models/sidenav';
import { SidenavParentV2 } from '../_models/sidenav_parent_v2';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class GlobalVariables {
    private observablesSelects: Array<BehaviorSubject<any>> = [];
    private observablesSelect: Map<string, Array<BehaviorSubject<any>>>;
    public selects: any = [] = [];
    public mainMenu: Sidenav[] = [];
    public mainMenuV2: Sidenav[];
    public mainMenuV2Parent: SidenavParentV2[];
    public isViewMenu = true;
    public isViewLoader = false;
    public countRequests = 0;

    /**
     * watch data of given key
     * @param key Watch data of given key
     * @returns defaultValue Default value
     */
    watchSelect(key: string): Observable<any> {
        if (typeof this.observablesSelect === 'undefined') {
            this.observablesSelect = new Map();
        }
        if (!this.observablesSelect.has(key)) {
            this.observablesSelect.set(key, new Array());
        }
        let observador = new BehaviorSubject<any>(null);
        let arrayObservables = this.observablesSelect.get(key);
        arrayObservables.push(observador);
        return observador.asObservable();
    }

    setSelect(key, data) {
        localStorage.setItem(key, JSON.stringify(data));

        this.selects[key] = data;
        if (typeof this.observablesSelect === 'undefined') {
            this.observablesSelect = new Map();
        }
        if (!this.observablesSelect.has(key)) {
            this.observablesSelect.set(key, new Array());
        }

        this.observablesSelect.get(key)
            .forEach(function (observador: BehaviorSubject<any>) {
                observador.next(data);
            });
    }
}
