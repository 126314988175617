import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'inmuebles',
    loadChildren: './modules/inmuebles/inmuebles.module#InmueblesModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: './modules/login/login.module#LoginModule'
  },
  {
    path: 'iframe',
    loadChildren: './modules/iframe/iframe.module#IframeModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'usuarios',
    loadChildren: './modules/usuarios/usuarios.module#UsuariosModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'informes-graficos',
    loadChildren: './modules/informes-graficos/informes-graficos.module#InformesGraficosModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'informes',
    loadChildren: './modules/informes/informes.module#InformesModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'callcenter',
    loadChildren: './modules/callcenter/callcenter.module#CallcenterModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'portales',
    loadChildren: './modules/portales/portales.module#PortalesModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'clientes',
    loadChildren: './modules/clientes/clientes.module#ClientesModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'proyectos',
    loadChildren: './modules/proyectos/proyectos.module#ProyectosModule',
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes
      // { enableTracing: true }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
