import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Bootstrap
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';

// snniper
import { NgxSpinnerModule } from 'ngx-spinner';

import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoaderInterceptorService } from './_helpers/loader-interceptor.service';
import { GlobalVariables } from './_helpers/global_variables';
import { UsuariosSocketService } from './_services/usuarios-socket.service';
// tslint:disable-next-line: max-line-length
import { ModalSincroPortalesInmuComponent } from './modules/inmuebles/components/modal-sincro-portales-inmu/modal-sincro-portales-inmu.component';
import { SafeUrlPipe } from './_pipes/safe-url.pipe';
import { TokensService } from './_interceptors/tokens.service';
@NgModule({
  declarations: [
    AppComponent,
    ModalSincroPortalesInmuComponent,
    SafeUrlPipe,
    
  ],
  entryComponents: [ModalSincroPortalesInmuComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CoreModule,
    TooltipModule.forRoot(),
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    NgxSpinnerModule
  ],
  providers: [
    GlobalVariables,
    BsModalRef,
    UsuariosSocketService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokensService,
      multi: true
    }
  ],
  exports: [
    NgxSpinnerModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
