import { Component } from '@angular/core';
import { AuthenticationService } from './_services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'modulo-crm-simi';
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    if (this.authenticationService.currentUserValue) {
      this.authenticationService.isLogued().subscribe((data: any) => {
        if (data.status === 0) {
          this.authenticationService.logout();
          this.router.navigate(['/login']);
        } else {
          this.authenticationService.loadSelects();
          this.authenticationService.loadMenusUser();
        }
      });
    }
  }
}
