import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestService } from 'src/app/_services/request.service';
import { Notificacion } from 'src/app/_models/notificacion';


@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.sass']
})
export class NotificacionComponent implements OnInit {

  @Input() public notificacion: Notificacion;
  @Output() leerNotificacion: EventEmitter<any> = new EventEmitter();
  constructor() {
  }
  ngOnInit() {

    //Verificicar si se crea la notificacion en el chrome

    var ahora = new Date();
    ahora.setSeconds(ahora.getSeconds() - 5);

    var fechaNotificacion = new Date(this.notificacion.fechaCreacion + " " + this.notificacion.horaCreacion);

    if(fechaNotificacion.getTime()  > ahora.getTime()){
      //la notificacion paso hace menos de 3 segundos
      this.notifyMe();

    }
  }
  public clickDescartarNotificacion() {


    var emmit = { 'id': this.notificacion.id, 'accion': 'cerrar' };
    this.leerNotificacion.emit(emmit);
  }

  public clickNotificacion() {
    this.leerNotificacion.emit({ 'id': this.notificacion.id, 'accion': 'abrir', 'url': this.notificacion.urlRedirect });
  }

  playAudio(urlSound) {
    let audio = new Audio();
    audio.src = urlSound;
    audio.load();
    audio.play();
  }
  notifyMe() {
    if (Notification.permission === 'granted') {
      // alert("2");
      // If it's okay let's create a notification
      var sounds = {
        0: 'https://simicrm.app/mwc/js/all-eyes-on-me.mp3',
        1: 'https://simi-cloud.com.co/mwc/js/sound/bbm_tone.mp3',
        2: 'https://simi-cloud.com.co/mwc/js/sound/el_verdadero_terror.mp3'

      }

      var options = {
        body: this.notificacion.mensaje,
        icon: this.notificacion.logo,
        // renotify: true,
        tag: this.notificacion.urlRedirect,
        sound: sounds[1]
      };

      var notification = new Notification(this.notificacion.titulo, options);

      notification.addEventListener(
        'click',
        (e:any) =>  {
          window.focus();
          e.target.close();
          window.open(this.notificacion.urlRedirect);
        },
        false
      );

      this.playAudio(sounds[1]);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          var options = {
            body: this.notificacion.mensaje,
            icon: this.notificacion.logo,
            // vrenotify: true,
            tag: this.notificacion.urlRedirect,
          };

          var notification = new Notification(this.notificacion.titulo  , options);

          notification.addEventListener(
            'click',
            (e:any) => {
              window.focus();
              e.target.close();
              window.open(this.notificacion.urlRedirect);
            },
            false
          );
          this.playAudio(sounds[1]);
          // notification.renotify // should return true
        }
      });
    }
  }

}
