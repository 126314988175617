import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class TokensService implements HttpInterceptor {
  headers: any = '';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const simicrm = req.url.indexOf(environment.urlcrm);
    const simicrmlogin = req.url.indexOf('crm/auth/login');
    // Interceptor crm laravel include token
    if (simicrm !== -1 && simicrmlogin === -1) {

      /* console.log(req.url); */
      const token = localStorage.getItem('tokenCrmApi');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        token
      });
      const reqCLone = req.clone({
        headers
      });
      return next.handle(reqCLone).pipe(
        catchError((err: HttpErrorResponse) => {

          console.log(err.status);
          if (err.status === 401) {
            this.router.navigateByUrl('/login');
            this.authenticationService.logout();
          }

          return throwError(err);

        })
      );


    }
    return next.handle(req);

  }


}
