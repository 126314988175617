import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplacePipe } from './replace.pipe';
import { Table2columnsPipe } from './table-2columns.pipe';
import { MomentDateFormatPipe } from './moment-date-format.pipe';
import { SafeHtmlPipe } from '../_pipes/safe-html.pipe';


@NgModule({
  declarations: [
    ReplacePipe,
    Table2columnsPipe,
    MomentDateFormatPipe,
    SafeHtmlPipe
  ],
  imports: [CommonModule],
  exports: [
    ReplacePipe,
    Table2columnsPipe,
    MomentDateFormatPipe,
    SafeHtmlPipe
  ]
})
export class PipesModule {
  static forRoot() {
    return {
      ngModule: PipesModule,
      providers: [],
    };
  }
 }
