export class Notificacion {
  id: number;
  titulo: string;
  mensaje: string;
  logo: string;
  tipo: string;
  idUser: number;
  fechaCreacion: string;
  horaCreacion: string;
  urlRedirect: string;
}
