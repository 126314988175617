import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../_services/authentication.service';
import { UsuariosSocketService } from '../../../_services/usuarios-socket.service';
import { SimipediaSocketService } from '../../../_services/simipedia-socket.service';
import { Subscription } from 'rxjs';
import { User } from '../../../_models/user';
import { environment } from '../../../../environments/environment';
import { RequestService } from '../../../_services/request.service';
import { Functions } from '../../../_helpers/functions';
import { GlobalVariables } from '../../../_helpers/global_variables';
import { ReplacePipe } from '../../../_pipes/replace.pipe';
import { LaravelSocketService } from 'src/app/_services/laravel-socket.services';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: User;
  ticket;
  urlPick = environment.urlifr;
  listTicketsUser = [];
  listPqrsUser = [];
  urlDomain = environment.urlifr;
  totalNotifications: number;
  // tslint:disable-next-line: variable-name
  _subsUsers: Subscription;
  // tslint:disable-next-line: variable-name
  _subsAnalista: Subscription;
  // tslint:disable-next-line: variable-name
  _subsCambioTareaAnalista: Subscription;
  // tslint:disable-next-line: variable-name
  _subsCambioCambioTicket: Subscription;
  // tslint:disable-next-line: variable-name
  _subsNotification_qualify: Subscription;
  // tslint:disable-next-line: variable-name
  _subsNotifyCredentials: Subscription;
  // tslint:disable-next-line: variable-name
  _subsNotifyCreateTicketClient: Subscription;
  // tslint:disable-next-line: variable-name
  _subsNotifySessionUser: Subscription;

  // tslint:disable-next-line: variable-name
  _subsNotifyResponseTicketClient: Subscription;
  // tslint:disable-next-line: variable-name
  _subsNotifyResponseUsrResponse: Subscription;

  public notificaciones;


  @ViewChild('notificacionBell') notificacionBell: ElementRef<HTMLElement>;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public usersSocket: UsuariosSocketService,
    public simipediaSocket: SimipediaSocketService,
    public laravelSocket: LaravelSocketService,
    public request: RequestService,
    private functions: Functions,
    private globalVariables: GlobalVariables
  ) {
    this.user = authenticationService.currentUserValue;
    this.usersSocket.nvoUser(this.user);
    authenticationService.currentUser.subscribe((user) => {
      this.user = user;
    });

    this.notificaciones = [];

  }

  ngOnInit() {
    this.notifications();
    $('.dropdown-toggle').dropdown();
    this.subsSocketUsuario();
    this.subsSocketSimiPedia();
    /* this.loadPqrs(); */
    /* if (this.user.idinmmo === '1') {
      this.loadTicketsUser();
    } else {
      this.loadTicketsClient();
    } */
  }
  openCloseMenu() {
    this.globalVariables.isViewMenu = !this.globalVariables.isViewMenu;
  }
  loadTicketsUser() {
    this.request
      .globalRequest(
        'post',
        { iduser: this.user.iduser },
        '/models/simipedia.php?data=53'
      )
      .subscribe(data => {
        this.listTicketsUser = data;
      });
  }
  loadTicketsClient() {
    this.request
      .globalRequest(
        'post',
        { iduser: this.user.iduser },
        '/models/simipedia.php?data=58'
      )
      .subscribe(data => {
        this.listTicketsUser = data;
      });
  }
  loadPqrs() {
    this.request.globalRequest('post', { usu_pqri: this.user.iduser, est: 1 }, '/models/simipediaNew.php?data=14').subscribe((data) => {
      if (data.aaData.length > 0) {
        const infoPends = data.aaData;
        if (infoPends[0].usr_pqri === this.user.iduser) {
          this.listPqrsUser = infoPends;
        }
      }
    });
  }
  subsSocketUsuario() {
    this._subsUsers = this.usersSocket.getNuevoUsuario().subscribe(data => {
      // console.log(data);

      this.usersSocket.usuariosCon = data.users;
      this.usersSocket.usuariosDisCon = data.usersDisconect;
      this.usersSocket.inmosCon = data.countInmos;
      this.usersSocket.total = data.users + data.usersDisconect;
      const indexDisConectUser = this.usersSocket.tblUsersDisConect.find(
        x => String(x.id) === String(data.id)
      );
      if (indexDisConectUser) {
        this.usersSocket.tblUsersDisConect.splice(
          this.usersSocket.tblUsersDisConect.indexOf(indexDisConectUser),
          1
        );
      }
      const indexConectUser = this.usersSocket.tblUsersConect.find(
        x => String(x.id) === String(data.id)
      );
      if (indexConectUser === undefined) {
        this.usersSocket.tblUsersConect.unshift({
          id: data.id,
          nombreCliente: data.nombreCliente,
          nombreInmo: data.nombreInmo
        });
      }
    });
  }
  subsSocketSimiPedia() {

    this._subsCambioCambioTicket = this.simipediaSocket.onNotifyCambioTicket().subscribe(data => {
      console.log(data);
      if (this.user.idinmmo === '1') {
        this.functions.notifyMe(
          data.titulo,
          data.mensaje,
          data.urlimage,
          0,
          data.urlredirect,
          null
        );
      }
    });
    this._subsCambioTareaAnalista = this.simipediaSocket
      .onCambioTareaAnalista()
      .subscribe(data => {
        if (data.id === this.user.iduser) {
          this.functions.notifyMe(
            'Alerta!! ',
            data.name + ' le ha reasignado una tarea ',
            data.avatar,
            0,
            environment.urlifr +
            '/mwc/simipedia/listadofilter_pqr.php?conse_pet=' +
            data.codPet,
            null
          );
        }
        this.listTicketsUser = data.ticketsUsuario || [];
      });
    this._subsNotification_qualify = this.simipediaSocket
      .onNotification_qualify()
      .subscribe(data => {
        if (data.user === this.user.iduser) {
          this.listTicketsUser = data.ticketsAgente || [];
        } else if (data.pllama === this.user.iduser) {
          this.listTicketsUser = data.ticketsInmoUser || [];
        }
        if (
          data.user === this.user.iduser ||
          (this.user.esAgente > 1 && this.user.idinmmo === '1')
        ) {
          this.functions.notifyMe(
            'Ticket: ' + data.id,
            data.message,
            data.imgInmob,
            0,
            environment.urlifr +
            '/mwc/simipedia/listadofilter_pqr.php?conse_pet=' +
            data.id,
            null
          );
        }
      });
    this._subsNotifyCredentials = this.simipediaSocket
      .onNotifyCredentials()
      .subscribe(data => {
        if (data.usuario === this.user.iduser) {
          this.functions.notifyMe(
            'Ticket: ' + data.id,
            'La  inmobiliaria ' + data.nomInmob + ' Envio credenciales ',
            data.imgInmob,
            0,
            environment.urlifr +
            '/mwc/simipedia/listadofilter_pqr.php?conse_pet=' +
            data.id,
            null
          );
        }
      });
    this._subsNotifyCreateTicketClient = this.simipediaSocket
      .onNotifyCreateTicketClient()
      .subscribe(data => {
        if (this.user.esAgente > 1 && this.user.idinmmo === '1') {
          this.functions.notifyMe(
            'Se creo Ticket: ' + data.id,
            'La  inmobiliaria ' + data.nomInmob + ' creo un nuevo ticket.',
            data.imgInmob,
            0,
            environment.urlifr +
            '/mwc/simipedia/listadofilter_pqr.php?conse_pet=' +
            data.id,
            null
          );
        }
      });



    // Notificaciones de laravel
    this._subsNotifySessionUser = this.laravelSocket.onUserSessionNotification(this.user.iduser)
      .subscribe(data => {
        try {
          this.globalVariables.selects.notificaciones.unshift(data);
        } catch (error) {
          console.error(error);
        }
      });
  }
  // notificaciones de laravel
  notifications() {

    if (this.globalVariables.selects.notificaciones) {

      this.notificaciones = this.globalVariables.selects.notificaciones;
    } else {
      setTimeout(() => {
        this.notifications();
      }, 1000);
    }
  }
  ngOnDestroy() {
    this._subsUsers.unsubscribe();
    // this._subsAnalista.unsubscribe();
    this._subsCambioTareaAnalista.unsubscribe();
    this._subsNotification_qualify.unsubscribe();
    this._subsNotifyCredentials.unsubscribe();
    this._subsNotifyCreateTicketClient.unsubscribe();
    // this._subsNotifyResponseTicketClient.unsubscribe();
    // this._subsNotifyResponseUsrResponse.unsubscribe();
    this._subsCambioCambioTicket.unsubscribe();
    this._subsNotifySessionUser.unsubscribe();
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  serachTicket() {
    if (this.ticket !== '') {
      const replace = new ReplacePipe();
      this.ticket = replace.transform(this.ticket, '[a-z]', '');
      this.router.navigate(['/iframe/5/conse_pet@' + this.ticket]);
    }
  }


  clickNotificacionesBell(event) {
    event.stopPropagation();

  }

  openAllNotificacions() {
    window.open('usuarios/notificaciones', '_blank');
  }

  /**
   * Se encarga de hace la peticion para hacer la lectura y eleminarlo de la vista
   */
  leerNotificacion(event, index) {


    this.request.globalRequestApiV2('post', {}, 'notificaciones/' + event.id + '/leer', 'urlApiCrm').subscribe((data) => {

      if (data.status === 1) {
        this.notificaciones.splice(index, 1);
        if (event.accion === 'abrir') {
          this.notificacionBell.nativeElement.click();
          window.open(event.url, '_blank');
        }
      }
    });



  }
}
