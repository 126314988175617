import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Functions } from '../_helpers/functions';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
import { catchError, timeout } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  headers: any = '';
  options: any = '';
  constructor(
    private http: HttpClient,
    private functions: Functions
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    this.options = { headers: this.headers };
  }

  globalRequest(method, json, url) {

    this.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    this.options = { headers: this.headers };
    let request: Observable<any>;
    if (method.toLowerCase() === 'get') {
      request = this.http.get(environment.url + url);
    } else {
      const dataform = this.functions.JSON_to_URLEncoded(json);
      request = this.http.post(environment.url + url, dataform, this.options);
    }
    return request;
  }
  /**
   * .
   * @param method .
   * @description POST o GET
   * @param json { } .
   * @param url .
   * @param typeService .
   * @description [urlApi: "https://www.simi-api.com" urlApiCrm: "https://simisoftware.com urlApiErp: "http://3.17.98.121:8080"]
   */
  globalRequestApi(method, json, url, typeService) {
    let request: Observable<any>;
    const user: User = JSON.parse(localStorage.getItem('currentUser'));
    const uriService = user[typeService];
    const token = localStorage.getItem('tokenCrmApi');

    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': token
    });
    this.options = { headers: this.headers };
    if (method.toLowerCase() === 'get') {
      request = this.http.get(uriService + '/' + url, this.options).pipe(timeout(180000), catchError(error => {
        // do something on a timeout
        console.warn('Timeout globalRequestApi', error);
        return of(null);
      }));
    } else {
      request = this.http.post(uriService + '/' + url, json, this.options).pipe(timeout(180000), catchError(error => {
        // do something on a timeout
        console.warn('Timeout globalRequestApi', error);
        return of(null);
      }));
    }
    return request;
  }
  globalRequestApiV2(method, json, url, typeService) {
    let request: Observable<any>;
    const token = localStorage.getItem('tokenCrmApi');
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': token
    });
    this.options = { headers: this.headers };
    if (method.toLowerCase() === 'get') {
      request = this.http.get(environment.SERVER_LARAVEL + '/' + url, this.options);
    } else {
      request = this.http.post(environment.SERVER_LARAVEL + '/' + url, json, this.options);
    }
    return request;
  }


  globalRequestCrmApi(method, json, url) {
    let request: Observable<any>;
    const user: User = JSON.parse(localStorage.getItem('currentUser'));
    const token = localStorage.getItem('tokenCrmApi');
    // this.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   token,
    // });
    // this.options = { headers: this.headers };
    if (method.toLowerCase() === 'get') {
      request = this.http.get(environment.urlcrm + url);
    } else {
      request = this.http.post(environment.urlcrm + url, json);
    }
    return request;
  }
}
